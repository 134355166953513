




















































































import {Vue, Component, Prop} from 'vue-property-decorator';
import CompanySearch from "@/components/common/CompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreateRERPayload, {CreateREREmployeePayload} from "@/dto/request/rer/CreateRERPayload";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import {namespace} from "vuex-class";
import RERService from "@/services/request/RERService";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import SelectOption from "@/components/common/SelectOption";
import Workspaces from "@/state/Workspaces";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import HireIndividualEmployee from "@/dto/payroll/HireIndividualEmployee";
import BranchDto from "@/components/profile/branches/BranchDtos";
import AddressDTO from "@/dto/AddressDTO";
import Application from "@/state/Application";
import CompanyService from "@/services/CompanyService";
import {GetByPublicIdDTO} from "@/dto/request/rer/GetByPublicIdDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import Notifications from "@/state/Notifications";

const AppModule = namespace("App");

@Component({
  components: {
    PortalSelect,
    PortalDate, SimpleCompanySearch, PortalCheckbox, PhoneInput, PortalInput, PortalCollapse, CompanySearch
  },
  computed: {
    PayPeriodType() {
      return PayPeriodType;
    },
    PayType() {
      return PayType;
    },
    SelectOption() {
      return SelectOption;
    }
  }
})
export default class CreateRER extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void


  private newEmployee = new CreateREREmployeePayload();
  private company: GetByPublicIdDTO | null = null
  private isCompanyLoaded = false;
  private addMode = false;
  private branches: BranchDto[] = [];

  private expandedEmployeeId: null | number = null;


  public mounted(): void {
    this.getCurrentCompany()
  }


  get id() {
    return this.$route.params.id;
  }

  private getCurrentCompany() {
    RERService.getByPublicId(this.id).then(
        response => {
          this.company = response.data;
          this.branches = this.company?.__branches as BranchDto[];
          this.isCompanyLoaded = true;
          if (this.company.employees?.length == 0) {
            this.addMode = true;
          }
        })
  }

  private buildPayPeriodTypes(): Array<SelectOption> {
    let payPeriodTypes: Array<SelectOption> = [];
    const periodTypes = Object.values(PayPeriodType);
    periodTypes.forEach(period => {
      payPeriodTypes.push(
          SelectOption.builder()
              .title(`${period}`)
              .value(period)
              .build()
      )
    })
    return payPeriodTypes;
  }

  private buildPayTypes(): Array<SelectOption> {
    let payTypes: Array<SelectOption> = [];
    const types = Object.values(PayType);
    types.forEach(type => {
      payTypes.push(
          SelectOption.builder()
              .title(`${type}`)
              .value(type)
              .build()
      )
    })
    return payTypes;
  }


  private buildWorkplaces(): Array<SelectOption> {
    let workplaces: Array<SelectOption> = [];
    this.newEmployee.branchesId
        .map(id => this.branches.find(it => it.id == id))
        .map(it => this.assembleAddresses(it!))
        .map(list => list.filter(address => !!address && !!address.fullAddress).map(address => SelectOption.builder().title(address!.fullAddress).value(address!.id).build()))
        .forEach(list => workplaces.push(...list))
    return workplaces;
  }


  private assembleAddresses(branch: BranchDto) {
    let res = [];
    if (branch.legalAddress && branch.legalAddress.fullAddress) {
      res.push(branch.legalAddress)
    }
    if (branch.actualAddress && branch.actualAddress.fullAddress) {
      res.push(branch.actualAddress)
    }
    if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
      res.push(branch.mailingAddress)
    }
    if (!AddressDTO.isEmpty(branch.registrationAgentAddress)) {
      res.push(branch.registrationAgentAddress)
    }
    return res;
  }

  private getBranchTitleAndAddressById(addressId: number) {
    for (const branch of this.company?.__branches as BranchDto[]) {
      const addresses = [
        branch.legalAddress,
        branch.mailingAddress,
        branch.actualAddress,
        branch.registrationAgentAddress
      ];

      for (const address of addresses) {
        if (address && address.id === addressId) {
          return {
            title: branch.title,
            address: address.fullAddress
          };
        }
      }
    }
    return { title: 'Not Found', address: 'Not Found' };
  }

  private addEmployee() {
    ifValid(this, () => {
          this.startLoading()
          RERService.addEmployee(this.newEmployee, this.id).then(
              ok => {
                this.stopLoading();
                this.newEmployee = new CreateREREmployeePayload();
                Notifications.success("A new employee has been successfully added");
                this.getCurrentCompany();
                this.addMode = false;
              },
              err => {
                this.stopLoading()
                processError(this, err);
              }
          )
        }, () => {
          Notifications.error("Please fill up all forms required")
        }
    )
  }

  private changeMode() {
    this.addMode = !this.addMode;
  }

  private toggleDetails(id: number) {
    if (this.expandedEmployeeId === id) {
      this.expandedEmployeeId = null;
    } else {
      this.expandedEmployeeId = id;
    }
  }
}
